import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, StaticImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";

import styled from "styled-components";

import Layout from "../components/layout";
import OurCommunity from "../screens/who-we-are/community";
import SEO from "../components/seo";
const WhoWeAreHero = styled(BgImage)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 1080px;

  section {
    margin-top: 50px;
  }
  .arrowSmall {
    display: none;
  }
  p {
    font-family: "Eames Century Modern", serif;
    font-size: 2.1875rem;
    font-style: italic;
    line-height: 2.625rem;
    text-align: center;
    color: white;
    padding-right: 40px;
    padding-left: 40px;
    max-width: 1120px;
  }
  @media screen and (max-width: 1111px) {
    #LOGO {
      width: 275px;
      height: auto !important;
      margin: auto;
    }
    p {
      font-size: 1.75rem;
    }
  }
  @media screen and (max-width: 621px) {
    #LOGO {
      width: 200px;
    }
    p {
      font-size: 1.25rem;
      line-height: 24px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .arrowBig {
      display: none;
    }
    .arrowSmall {
      margin-top: 40px;
      width: 100%;
      display: block;
    }
  }
`;

const GridSection = styled.section`
    display: grid;
    min-height: 100vh;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 320px;
    grid-template-areas:
      "cdev      cdev       pic1     pic1"
      "illus1 pic2 illus2 pic3"
      "pic4 illus3 cdes cdes"
      "hr hr pic5 pic6"
      "pic7 pic7 illus4 pic6"
      "pic8 illus5 finance finance"
      "rnd rnd pic9 pic10"
      "illus6 pic11 illus7 pic10"
      "pic12 illus8 osg osg";

    .title {
      font-family: "Eames Century Modern", serif;
      font-size: 3.125rem;
      font-style: italic;
      color: white;
    }
    .details {
      font-family: "Brandon Text";
      font-size: 1.25rem;
      line-height: 1.35;
      max-width: 548px;
    }
    .description-box {
      display:flex;
      justify-content:center;
      align-items:center;
      padding: 2.625rem;
    }
    .community-development {
      grid-area: cdev;
      background-color: #f37549;
    }

    .community-design {
      grid-area: cdes;
      background-color: #ef435a;
    }
    .human-resources {
      grid-area: hr;
      background-color: #f69a53;
    }
    .finance {
      grid-area: finance;
      background-color: #ef435a;
    }
    .osg {
      grid-area: osg;
      background-color: #ef435a;
    }
    .rnd {
      grid-area: rnd;
      background-color: #f69a53;
    }
    .pic1 {
      grid-area: pic1;
    }
    .pic2 {
      grid-area: pic2;
    }
    .pic3 {
      grid-area: pic3;
    }
    .pic4 {
      grid-area: pic4;
    }
    .pic5 {
      grid-area: pic5;
    }
    .pic6 {
      grid-area: pic6;
    }
    .pic7 {
      grid-area: pic7;
    }
    .pic8 {
      grid-area: pic8;
    }
    .pic9 {
      grid-area: pic9;
    }
    .pic10 {
      grid-area: pic10;
    }
    .pic11 {
      grid-area: pic11;
    }
    .pic12 {
      grid-area: pic12;
    }
    .illus1 {
      grid-area: illus1;
      background-color: #ef435a;
    }
    .illus2 {
      grid-area: illus2;
      background-color: #f69a53;
    }
    .illus3 {
      grid-area: illus3;
      background-color: #f37549;
    }
    .illus4 {
      grid-area: illus4;
      background-color: #f69a53;
    }
    .illus5 {
      grid-area: illus5;
      background-color: #f37549;
    }
    .illus6 {
      grid-area: illus6;
      background-color: #ef435a;
    }
    .illus7 {
      grid-area: illus7;
      background-color: #f37549;
    }
    .illus8 {
    grid-area: illus8;
    background-color: #f69a53;
  }

  [class^="illus"] {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width:1470px){
    .title {
      font-size: 2.5rem;
    }
  }
  @media screen and (max-width:1220px){
    .title {
      font-size: 2rem;
    }
  }
  @media screen and (max-width: 1040px) {
    .description-box {
      padding: 2rem ;
    }
  }

  @media screen and (max-width: 1032px) {
    .description-box {
      > .title {
        font-size: 1.75rem;
      }
    }
    > .details {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  @media screen and (max-width: 880px) {
    .description-box {
      padding: 1.5rem ;
      > .title {
        font-size: 1.5rem;
      }
    }
  }
}
  @media screen and (max-width: 760px) {
    .description-box {
      padding: 1.5rem;
      > .title {
        font-size: 1.25rem;
      }
      > .details {
        font-size: 16px; 
        line-height: 1.5;
      }
    }
  }
  @media screen and (max-width: 603px) {
  grid-template-areas:
    "cdev      cdev       pic1     pic1"
    "pic2 illus2 illus2 pic3"
    "pic4 illus3 cdes cdes"
    "hr hr pic5 pic5"
    "pic7 pic7 pic6 pic6"
    "pic8 illus5 finance finance"
    "rnd rnd pic9 pic10"
    "illus6 pic11 illus7 pic10"
    "pic12 illus8 osg osg";

      .illus1 {
        display: none;
      }
      .illus4 {
        display:none;
      }

    .description-box {
      padding: 1rem;
      > .title {
        font-size: 1.0rem;
      }
      > .details {
        font-size: 16px; 
        line-height: 1.5;
      }
    }
  }
  @media screen and (max-width: 500px) {
    grid-template-areas: "cdev cdev pic1 pic1"
    "pic2 illus2 illus2 pic3"
    "pic4 pic4 cdes cdes"
    "hr hr hr hr"
    "pic7  pic7 pic7 pic7"
    "illus3 illus3 pic5 pic5"
    "pic6 pic6 finance finance"
    "illus4 illus4 pic8 pic8"
    "rnd rnd rnd rnd"
    "pic9 pic9 illus6 illus6"
    "osg osg pic10 pic10"
    "pic12 pic12 pic11 pic11";
  [class^="illus"] {
    overflow:hidden;
  }
    grid-template-rows: 350px 152px 320px 158px 160px 160px 320px 160px 200px 160px 320px 160px;
      .illus1 {
        display: none;
      }
      .illus4 {display: block;}
      .illus5 {
        display:none;
      }
      .illus7 {
        display:none;
      }
      .illus8 {
        display:none;
      }
      .human-resources.description-box  {max-height: 160px;}

    .description-box {
      text-align:center; 
      padding: 2.5rem;
      > .title {
        font-size: 1.25rem;
      }
      > .details {
        font-size: 12px; 
        line-height: 1.5;
      }
    }
  }
`;

function WhoWeAre() {
  const { placeholderImage, SEOImg } = useStaticQuery(graphql`
    query WhoWeAreHeroBG {
      placeholderImage: file(relativePath: { eq: "WhoWeAre/HeroBG.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1500
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
      SEOImg: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const pluginImage = getImage(placeholderImage);
  const backgroundFluidImageStack = [
    `linear-gradient(rgba(0,0,0, 0.73), rgba(0, 0, 0, 0.73))`,
    pluginImage,
  ];

  return (
    <Layout identifier="Our Departments">
      <SEO title="Our Departments" image={SEOImg.childImageSharp.fluid} />
      <WhoWeAreHero image={backgroundFluidImageStack}>
        <StaticImage
          id="LOGO"
          src="../images/OurWork/OurWorkLogoLAAN.png"
          placeholder="blurred"
          width={303.75}
          alt=""
        ></StaticImage>
        <p>
          We are a community that advocates for the right to health of all
          Filipinos through service, empowerment, creativity and innovation.
        </p>
        <section>
          <svg
            className="arrowBig"
            style={{ marginTop: "52px" }}
            width="30"
            height="88"
            viewBox="0 0 30 88"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.5858 87.4142C14.3668 88.1953 15.6332 88.1953 16.4142 87.4142L29.1421 74.6863C29.9232 73.9052 29.9232 72.6389 29.1421 71.8579C28.3611 71.0768 27.0948 71.0768 26.3137 71.8579L15 83.1716L3.68629 71.8579C2.90524 71.0768 1.63891 71.0768 0.857864 71.8579C0.0768156 72.6389 0.0768156 73.9052 0.857864 74.6863L13.5858 87.4142ZM13 0L13 86H17L17 0L13 0Z"
              fill="white"
            />
          </svg>
          <svg
            className="arrowSmall"
            width="16"
            height="55"
            viewBox="0 0 16 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.29289 54.7071C7.68342 55.0976 8.31658 55.0976 8.70711 54.7071L15.0711 48.3432C15.4616 47.9526 15.4616 47.3195 15.0711 46.9289C14.6805 46.5384 14.0474 46.5384 13.6569 46.9289L8 52.5858L2.34315 46.9289C1.95262 46.5384 1.31946 46.5384 0.928932 46.9289C0.538408 47.3195 0.538408 47.9526 0.928932 48.3432L7.29289 54.7071ZM7 0L7 54H9L9 0L7 0Z"
              fill="white"
            />
          </svg>
        </section>
      </WhoWeAreHero>
      <GridSection>
        <div className="community-development description-box">
          <p className="title">Community Development</p>
        </div>
        <div className="pic1">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic1.jpeg"
            placeholder="blurred"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>
        <div className="illus1">
          <StaticImage
            src="../images/WhoWeAre/Grid/illustration1.png"
            quality={90}
            alt=""
            style={{ overflow: "hidden" }}
          ></StaticImage>
        </div>
        <div className="pic2">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic2.jpeg"
            objectPosition="50% 70%"
            placeholder="blurred"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>
        <div className="illus2">
          <StaticImage
            src="../images/WhoWeAre/Grid/illustration2.png"
            quality={90}
            alt=""
          ></StaticImage>
        </div>
        <div className="pic3">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic3.jpeg"
            placeholder="blurred"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>
        <div className="pic4">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic4.jpeg"
            placeholder="blurred"
            objectPosition="50% 60%"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>
        <div className="illus3">
          <StaticImage
            src="../images/WhoWeAre/Grid/illustration3.png"
            quality={90}
            alt=""
          ></StaticImage>
        </div>
        <div className="community-design description-box ">
          <p className="title">Communication Design</p>
        </div>
        <div className="human-resources description-box">
          <p className="title">Human Resources</p>
        </div>
        <div className="pic5">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic5.jpeg"
            placeholder="blurred"
            objectPosition="50% 40%"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>
        <div className="pic6">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic6.jpeg"
            placeholder="blurred"
            objectPosition="50% 40%"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>

        <div className="pic7">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic7.jpg"
            placeholder="blurred"
            objectPosition="50% 20%"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>
        <div className="illus4">
          <StaticImage
            src="../images/WhoWeAre/Grid/illustration4.png"
            quality={90}
            alt=""
          ></StaticImage>
        </div>
        <div className="pic8">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic8.jpeg"
            placeholder="blurred"
            objectPosition="50% 50%"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>
        <div className="illus5">
          <StaticImage
            src="../images/WhoWeAre/Grid/illustration5.png"
            quality={90}
            alt=""
          ></StaticImage>
        </div>
        <div className="finance description-box">
          <p className="title">Finance</p>
        </div>
        <div className="rnd description-box">
          <p className="title">Research &amp; Discourse</p>
        </div>
        <div className="pic9">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic9.jpeg"
            placeholder="blurred"
            objectPosition="50% 50%"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>
        <div className="pic10">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic10.jpeg"
            placeholder="blurred"
            objectPosition="50% 50%"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>
        <div className="illus6">
          <StaticImage
            src="../images/WhoWeAre/Grid/illustration6.png"
            quality={90}
            alt=""
          ></StaticImage>
        </div>
        <div className="pic11">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic11.jpeg"
            placeholder="blurred"
            objectPosition="50% 50%"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>
        <div className="illus7">
          <StaticImage
            src="../images/WhoWeAre/Grid/illustration7.png"
            quality={90}
            alt=""
          ></StaticImage>
        </div>

        <div className="pic12">
          <StaticImage
            src="../images/WhoWeAre/Grid/pic12.jpeg"
            placeholder="blurred"
            objectPosition="50% 50%"
            alt=""
            style={{ height: "100%" }}
            quality={90}
          ></StaticImage>
        </div>

        <div className="illus8">
          <StaticImage
            src="../images/WhoWeAre/Grid/illustration8.png"
            quality={90}
            alt=""
          ></StaticImage>
        </div>
        <div className="osg description-box">
          <p className="title">Office of the Secretary General</p>
        </div>
      </GridSection>
      <div>
        <OurCommunity />
      </div>
    </Layout>
  );
}

export default WhoWeAre;
