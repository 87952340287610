import React from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { StaticImage } from "gatsby-plugin-image";
const CommunitySection = styled.section`
  display: flex;
  padding: 7rem 4.5rem;
  padding-bottom: 15rem;
  padding-right: 8rem;
  background-color: #fbf7f3;
  overflow: hidden;
  div {
    width: 345px;
    height: 410px;
  }
  > * {
    margin-right: 6rem;
    flex-shrink: 0;
  }
`;
function Community() {
  const section = React.useRef();
  React.useEffect(() => {
    let sections = gsap.utils.toArray(section.current.children);
    let tl;
    if (section.current) {
      tl = gsap.to(sections, {
        xPercent: -100 * (sections.length + 1.5),
        ease: "none",
        scrollTrigger: {
          trigger: section.current,
          pin: true,
          scrub: true,
          start: "-=100",
          end: "+=800",
          anticipatePin: 1,
        },
      });
    }
  }, []);

  return (
    <CommunitySection ref={section}>
      <div>
        <StaticImage
          width={345}
          height={410}
          quality={90}
          placeholder="blurred"
          alt=""
          src="../../images/WhoWeAre/Community/Group-1.png"
        />
      </div>
      <div>
        <StaticImage
          width={345}
          height={410}
          quality={90}
          placeholder="blurred"
          alt=""
          src="../../images/WhoWeAre/Community/Group-2.png"
        />
      </div>
      <StaticImage
        width={345}
        height={410}
        alt=""
        quality={90}
        placeholder="blurred"
        src="../../images/WhoWeAre/Community/Group-3.png"
      />
      <div>
        <StaticImage
          alt=""
          width={345}
          height={410}
          quality={90}
          placeholder="blurred"
          src="../../images/WhoWeAre/Community/Group-4.png"
        />
      </div>
      <div>
        <StaticImage
          width={345}
          height={410}
          alt=""
          quality={90}
          placeholder="blurred"
          src="../../images/WhoWeAre/Community/Group-5.png"
        />
      </div>
      <div>
        <StaticImage
          width={345}
          height={410}
          alt=""
          quality={90}
          placeholder="blurred"
          src="../../images/WhoWeAre/Community/Group-6.png"
        />
      </div>
      <div>
        <StaticImage
          width={345}
          height={410}
          quality={90}
          placeholder="blurred"
          src="../../images/WhoWeAre/Community/Group-7.png"
        />
      </div>
      <div>
        <StaticImage
          width={345}
          height={410}
          alt=""
          quality={90}
          placeholder="blurred"
          src="../../images/WhoWeAre/Community/Group-8.png"
        />
      </div>
      <div>
        <StaticImage
          width={345}
          height={410}
          alt=""
          quality={90}
          placeholder="blurred"
          src="../../images/WhoWeAre/Community/Group-9.png"
        />
      </div>
    </CommunitySection>
  );
}

export default Community;
